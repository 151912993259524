<template>
<div v-if="adReady" class="bg-kpmg-purple">
  <b-container class="text-white" style="min-height: 90vh">
    <b-row class="py-5">
      <b-col>
        <h3>Controls</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-tabs>
          <b-tab title="Functional" v-if="ad.functions">
            <b-container class="my-4">
              <b-row>
                <b-col v-for="fun in ad.functions" :key="'function-' + fun.id" cols="3" class="mb-5">
                  <h4>{{fun.name}}</h4>
                  <ol v-if="fun.controls.length > 0">
                    <li v-for="control in fun.controls" :key="'control-' + control.id">
                      <b-link class="text-white" :to="{ name: 'AppUTControl', params: { id: control.id }}">{{control.name}}</b-link>
                    </li>
                  </ol>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab title="Lifecycle" v-if="ad.cycles">
            <b-container class="my-5 text-centre">
              <b-row class="mb-5 text-centre">
                <b-col class="mb-5 text-centre">
                  <div  id="chart" style="width: 800px; height: 800px;"></div>
                </b-col>
              </b-row>
              <b-row v-for="cycle in ad.cycles" :key="'cycle-' + cycle.id" class="mb-5">
                <b-col cols="3">
                  <h4>{{cycle.name}}</h4>
                </b-col>
                <b-col>
                  <ol v-if="cycle.controls.length > 0">
                    <li v-for="control in cycle.controls" :key="'control-' + control.id">
                      <b-link class="text-white" :to="{ name: 'AppUTControl', params: { id: control.id }}">{{control.name}}</b-link>
                    </li>
                  </ol>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab title="Objectives">
            <b-container class="my-4">
              <b-row v-for="control in controls" :key="'control-list-' + control.id" class="my-3">
                <b-col>
                  <b-link :to="{ name: 'AppUTControl', params: { id: control.id }}"><h4>{{control.name}}</h4></b-link>
                  <p>{{control.description}}</p>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab title="Attributes">
            <b-container class="my-4">
              <b-row v-for="control in controls" :key="'control-list-' + control.id" class="my-3">
                <b-col>
                  <b-link :to="{ name: 'AppUTControl', params: { id: control.id }}"><h4>{{control.name}}</h4></b-link>
                  <ul>
                    <li v-for="attribute in control.attributes" :key="'control-'+ control.id + '-attr-' + attribute.id">
                      <strong>{{attribute.name}}</strong> - {{attribute.description}}
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>
<script>
import _ from 'lodash'
import * as echarts from 'echarts'

export default {
  components: {
  },
  computed: {
    ad: function () {
      return this.$store.state.appData
    },
    adReady: function () {
      if (this.ad.configuration) {
        return true
      }
      return false
    },
    controls: function () {
      return _.sortBy(this.ad.controls, ['name'])
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'UT app start', action: 'open UT app' })
  },
  mounted: function () {
    if (this.ad.cycles) {
      this.drawGraph()
    }
  },
  data () {
    return {
    }
  },
  methods: {
    drawGraph: function () {
      const graphData = []
      _.each(this.ad.cycles, cycle => {
        const node = {
          name: cycle.name,
          children: []
        }
        _.each(cycle.controls, control => {
          const child = {
            name: control.name,
            value: 1
          }
          node.children.push(child)
        })
        graphData.push(node)
      })

      const option = {
        series: {
          radius: ['0%', '95%'],
          type: 'sunburst',
          sort: null,
          emphasis: {
            focus: 'ancestor'
          },
          data: graphData,
          label: {
            rotate: 'radial'
          },
          levels: [],
          itemStyle: {
            color: '#470A68',
            borderWidth: 1,
            borderColor: 'white'
          }
        }
      }
      echarts.init(document.getElementById('chart'), 'kpmg').setOption(option)
    }
  }
}
</script>
